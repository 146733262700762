import { gql } from "@apollo/client";

export const LOGIN = gql`
  query Login {
    login {
      id
      name
      email
      has_pat
      is_partner
      is_cliente
      is_opiquad
      is_admin
      avatar
      avatar_text
      all_permissions
      customer {
        id
        ragione_sociale
      }
      represented_customers {
        id
        ragione_sociale
      }
    }
  }
`;
