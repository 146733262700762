export enum Permissions {
  CLIENTI_LIST = "clienti:list",
  CLIENTI_DETAIL = "clienti:detail",
  CLIENTI_NUOVO = "clienti:nuovo",

  DATI_ACCESSORI_EDIT = "dati-accessori:edit",
  DATI_ACCESSORI_CREATE = "dati-accessori:create",
  DATI_ACCESSORI_DELETE = "dati-accessori:delete",

  MANAGEMENT_DAD = "management:dad",
  MANAGEMENT_DOMINI = "management:domini",
  MANAGEMENT_PRIVATE_CLOUD = "management:private-cloud",
  MANAGEMENT_OFFICE365 = "management:office365",

  RICERCA = "ricerca",

  SERVIZI = "servizi",

  SERVIZIO_ATTIVA = "servizio:attiva",
  SERVIZIO_DAD_EDIT = "servizio:dad:edit",
  SERVIZIO_DOMINIO_EDIT = "servizio:domini:edit",
  SERVIZIO_LIBRAESVA_EDIT = "servizio:libraesva:edit",

  ZIMBRA_EDIT = "zimbra:edit",
  ZIMBRA_DOMAIN_DELETE = "zimbra:domain.delete",
  ZIMBRA_DOMAIN_CREATE = "zimbra:domain.create",
  ZIMBRA_DOMAIN_QUOTA_CHANGE = "zimbra:domain.quota.change",
  ZIMBRA_DOMAIN_QUOTA_UNLOCK = "zimbra:domain.quota.unlock",
  ZIMBRA_DOMAIN_QUOTA_EDIT_EMAIL_RECIPIENT = "zimbra:domain.quota.edit-email-recipient",
  ZIMBRA_MAILBOX_ADD_OVERQUOTA = "zimbra:mailbox.add-overquota",
  ZIMBRA_MAILBOX_CHANGE_TYPE = "zimbra:mailbox.change-type",

  USERS_READ = "users:read",
  USERS_UPSERT = "users:upsert",
  USERS_INVITE = "users:invite",
  USERS_DELETE = "users:delete",
  USERS_RESET_2FA = "users:reset-2fa",
  USERS_UNLOCK = "users:unlock",
}

export type PermissionValue = `${Permissions}`;
