import { FC, PropsWithChildren } from "react";

import classNames from "classnames";
import styles from "./Container.module.scss";

interface ContainerProps {
  className?: string;
  size?: "large" | "medium" | "small" | "full";
}

const Container: FC<PropsWithChildren<ContainerProps>> = ({
  className,
  children,
  size = "large",
}) => {
  return (
    <div
      className={classNames(styles.Container, className, {
        [styles.Large]: size === "large",
        [styles.Medium]: size === "medium",
        [styles.Small]: size === "small",
        [styles.Full]: size === "full",
      })}
    >
      {children}
    </div>
  );
};

export default Container;
