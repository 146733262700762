import { useState } from "react";

const useModal = <T>() => {
  const [visible, setVisible] = useState<T | boolean>(false);

  const hide = () => {
    setVisible(false);
  };

  const show = (value: any = true) => {
    setVisible(value);
  };

  return { hide, show, visible };
};

export default useModal;
