import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
  gql,
} from "@apollo/client";
import { ErrorResponse, onError } from "@apollo/client/link/error";

import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { Permissions } from "@shared/config/permissions";
import { ProductQuery_product_product } from "../types/ProductQuery";
import Router from "next/router";
import { createUploadLink } from "apollo-upload-client";
import { currentUserVar } from "@shared/graphql/vars";
import { extractFiles } from "extract-files";
import { typeDefs } from "@shared/graphql/local-schema";
import userHasPermission from "@shared/helpers/user-has-permission";

const LINK_OPTIONS = {
  uri: `${process.env.NEXT_PUBLIC_API || "/api"}/graphql`,
};

const httpLink = ApolloLink.split(
  (operation) => extractFiles(operation).files.size > 0,
  createUploadLink(LINK_OPTIONS),

  ApolloLink.split(
    (operation) =>
      ["ProductsStatsQuery", "GetCategoriesTree"].includes(
        operation.operationName
      ),
    new HttpLink(LINK_OPTIONS),
    new BatchHttpLink(LINK_OPTIONS)
  )
);

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => {
    const token = localStorage
      .getItem(process.env.NEXT_PUBLIC_LS_TOKEN as string)
      ?.replaceAll('"', "");

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  });

  return forward(operation);
});

const logoutLink = onError((res: ErrorResponse) => {
  if (res.graphQLErrors) {
    for (const err of res.graphQLErrors) {
      if (err.extensions) {
        switch (err.extensions.status) {
          case 401:
            Router.push("/logout");
            break;

          case 404:
            Router.push("/404");
            break;

          case 403:
            Router.push("/403");
            break;

          case 500:
            Router.push("/500");
            break;
        }
      }
    }
  }
});

const client = new ApolloClient({
  connectToDevTools: process.env.NEXT_PUBLIC_ENV === "development",
  link: from([authMiddleware, logoutLink, httpLink]),
  typeDefs,
  cache: new InMemoryCache({
    typePolicies: {
      ZimbraAlias: {
        keyFields: ["alias"],
      },
      ZimbraDomain: {
        keyFields: ["zimbra_id"],
      },
      ZimbraEmail: {
        keyFields: ["zimbra_id"],
      },
      List: {
        keyFields: ["zimbra_id"],
      },
      DistributionList: {
        keyFields: ["zimbra_id"],
      },
      Row: {
        fields: {
          isEditable: {
            read(_, { cache, readField }) {
              const user = currentUserVar()!;
              const isActive = readField("is_attivo");
              const unaTantum = readField("una_tantum");

              const product: Pick<
                ProductQuery_product_product,
                "id" | "codice"
              > | null = cache.readFragment({
                id: cache.identify(readField("product") as any),
                fragment: gql`
                  fragment P on Product {
                    id
                    codice
                  }
                `,
              });

              if (!product || !product.codice || !isActive || unaTantum) {
                return false;
              }

              const { codice } = product;

              // prettier-ignore
              if (codice === "DAD") {
                return userHasPermission( user, Permissions.SERVIZIO_DAD_EDIT );
                
              } else if (codice.startsWith("DOMINIO") || codice === "DNS") {
                return userHasPermission(
                  user,
                  Permissions.SERVIZIO_DOMINIO_EDIT
                );

              } else if (codice.startsWith("EMAIL_SECURITY")) {
                return userHasPermission(
                  user,
                  Permissions.SERVIZIO_LIBRAESVA_EDIT
                );

              } else if (codice.startsWith("3DMAILBOX")) {
                return userHasPermission(user, Permissions.ZIMBRA_EDIT);
              }

              return false;
            },
          },
        },
      },
    },
  }),
});

export default client;
