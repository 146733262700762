import { FC, useContext } from "react";

import Head from "next/head";
import { SeoContext } from "@shared/contexts/seo";

interface DocumentTitleProps {
  title?: string;
}

const DocumentTitle: FC<DocumentTitleProps> = ({ title = null }) => {
  const appName = useContext(SeoContext);

  const baseTitle = `${appName} - Opiquad`;

  const t = title ? `${title} | ${baseTitle}` : baseTitle;

  return (
    <Head>
      <title>{t}</title>
    </Head>
  );
};

export default DocumentTitle;
