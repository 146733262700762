import { Button, Modal, Typography } from "antd";
import React, { FC } from "react";

import dynamic from "next/dynamic";
import { dynamicLoadSettings } from "@shared/config/dynamic";
import styles from "./RepresentedCustomerNotice.module.scss";
import useModal from "@shared/hooks/use-modal";

interface RepresentedCustomerNoticeProps {
  customer: string;
  canChange: boolean;
}

const ChangeCustomerModal = dynamic(
  () => import("@shared/containers/change-customer-modal"),
  { ...dynamicLoadSettings }
);

const RepresentedCustomerNotice: FC<RepresentedCustomerNoticeProps> = ({
  customer,
  canChange,
}) => {
  const changeCustomerModal = useModal<boolean>();

  return (
    <>
      <div className={styles.ActingAs}>
        <Typography.Text>{customer}</Typography.Text>

        {canChange && (
          <Button
            type="link"
            className={styles.Link}
            onClick={changeCustomerModal.show}
          >
            Cambia cliente
          </Button>
        )}
      </div>

      <Modal
        footer={null}
        title="Cambia cliente"
        onCancel={changeCustomerModal.hide}
        open={changeCustomerModal.visible}
      >
        <ChangeCustomerModal onFinish={changeCustomerModal.hide} />
      </Modal>
    </>
  );
};

export default RepresentedCustomerNotice;
