import { Login_login } from "@shared/graphql/types/Login";
import { makeVar } from "@apollo/client";

export const domainVar = makeVar<string>("");
export const productIdVar = makeVar<string>("");
export const currentUserVar = makeVar<Login_login | null>(null);

export const dashboardLink = makeVar<string>(
  process.env.NEXT_PUBLIC_DASHBOARD_PATH as string
);
