import React, { FC, PropsWithChildren } from "react";
import { currentUserVar, dashboardLink } from "@shared/graphql/vars";

import { useAccessToken } from "@shared/hooks/use-access-token";
import { useRedirectAfterLogin } from "@shared/hooks/use-redirect-after-login";
import { useRouter } from "next/router";

const PublicRoute: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter();
  const user = currentUserVar();
  const [token] = useAccessToken();
  const [redirect] = useRedirectAfterLogin();

  if (token) {
    router.replace(redirect || user ? dashboardLink() : "/dashboard");
    localStorage.removeItem("redirect_after_login");
    return null;
  }

  return <>{children}</>;
};

export default PublicRoute;
