import React, { FC, ReactNode } from "react";

import { Button } from "antd";
import Link from "next/link";
import { UrlObject } from "url";
import classNames from "classnames";
import styles from "./IconLink.module.scss";

interface IconLinkProps {
  active: boolean;
  href: string | UrlObject;
  icon: ReactNode;
}

const IconLink: FC<IconLinkProps> = ({ icon, active, href }) => {
  return (
    <Link passHref href={href}>
      <Button
        type="link"
        shape="circle"
        icon={icon}
        className={classNames(styles.Icon, {
          [styles.Active]: active,
        })}
      />
    </Link>
  );
};

export default IconLink;
