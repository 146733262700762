import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import { currentUserVar, dashboardLink } from "@shared/graphql/vars";

import Footer from "@shared/components/footer";
import Header from "@shared/components/header";
import { LOGIN } from "@shared/graphql/queries/login";
import { Layout } from "antd";
import { Login } from "@shared/graphql/types/Login";
import PageLoader from "@shared/components/page-loader";
import { useAccessToken } from "@shared/hooks/use-access-token";
import { useQuery } from "@apollo/client";
import { useRouter } from "next/router";

const AppLayout: FC<PropsWithChildren<unknown>> = (props) => {
  const router = useRouter();
  const [accessToken] = useAccessToken();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const isDashboardRef = useRef<boolean>(router.pathname === "/dashboard");

  useEffect(() => {
    if (router.pathname !== "/dashboard" && isDashboardRef.current) {
      isDashboardRef.current = false;
      setIsVerified(true);
    }
  }, [router]);

  const { loading } = useQuery<Login>(LOGIN, {
    skip: !accessToken,
    onError: () => {
      router.push("/logout");
    },
    onCompleted: (e: Login) => {
      currentUserVar(e.login);

      // Se l'utente è un cliente cambio il link alla sua dashboard
      if (e.login.is_cliente) {
        dashboardLink(process.env.NEXT_PUBLIC_CLIENTI_DASHBOARD_PATH as string);
      }

      if (!isDashboardRef.current) {
        setIsVerified(true);
      } else {
        router.replace(
          e.login.is_cliente
            ? (process.env.NEXT_PUBLIC_CLIENTI_DASHBOARD_PATH as string)
            : (process.env.NEXT_PUBLIC_DASHBOARD_PATH as string)
        );
      }
    },
  });

  if (loading || !isVerified) {
    return <PageLoader />;
  }

  return (
    <Layout className="site-layout">
      <Header />
      <Layout.Content>{props.children}</Layout.Content>
      <Footer />
    </Layout>
  );
};

export default AppLayout;
