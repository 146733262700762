import { Divider, Layout, Space, Typography } from "antd";
import React, { FC } from "react";

import Container from "@shared/components/container";
import { HeartFilled } from "@ant-design/icons";
import Image from "next/image";
import { format } from "date-fns";
import icone from "@public/footer-icons.png";
import logo from "@public/logo.png";
import styles from "./Footer.module.scss";

const Footer: FC = () => {
  return (
    <div className={styles.Wrapper}>
      <Layout.Footer className={styles.Footer}>
        <Container>
          <div className={styles.Logo}>
            <Image src={logo} alt={process.env.NEXT_PUBLIC_NAME} />
          </div>

          <Typography.Text className={styles.Copy}>
            <b>Opiquad srl a socio unico</b> <br />
            Via Bergamo, 60 – Merate 23807 LC <br />
            Codice Fiscale / Partita Iva 05866450967 <br />
            &copy; {format(new Date(), "yyyy")} Opiquad – Tutti i diritti
            riservati – Società soggetta a direzione e coordinamento di Opiquad
            Holding srl
          </Typography.Text>

          <Space
            wrap
            size={4}
            className={styles.Links}
            split={<Divider type="vertical" />}
          >
            <a
              target="_blank"
              rel="noreferrer"
              className={styles.SviluppoSicuro}
              href="https://www.opiquad.it/policy-di-sviluppo-sicuro/"
            >
              Policy di sviluppo sicuro
            </a>

            <Typography.Text>
              R-innovazione digitale by{" "}
              <a
                title="OpiHUB"
                target="_blank"
                rel="noreferrer"
                className={styles.Hub}
                href="https://www.opiquad.it/opihub"
              >
                OpiHUB <HeartFilled />
              </a>
            </Typography.Text>
          </Space>
        </Container>

        <div className={styles.Icone}>
          <Image src={icone} alt="Icone placeholder" />
        </div>
      </Layout.Footer>
    </div>
  );
};

export default Footer;
