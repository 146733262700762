import { Avatar, Dropdown } from "antd";
import React, { FC } from "react";

import Link from "next/link";
import classNames from "classnames";
import { currentUserVar } from "@shared/graphql/vars";
import styles from "./UserBadge.module.scss";
import { useRouter } from "next/router";

const UserBadge: FC = () => {
  const router = useRouter();
  const user = currentUserVar()!;

  return (
    <>
      <Dropdown
        arrow
        trigger={["click"]}
        placement="bottomRight"
        menu={{
          selectedKeys: [`/${router.pathname.split("/")[1]}`],
          items: [
            { label: user.name, type: "group" },
            { type: "divider" },
            {
              key: "/profilo",
              label: <Link href="/profilo">Il mio profilo</Link>,
            },
            {
              key: "logout",
              label: <Link href="/logout">Logout</Link>,
            },
          ],
        }}
      >
        <Avatar
          src={user?.avatar || undefined}
          className={classNames(styles.Avatar, {
            [styles.WithBorder]: !!user.avatar,
          })}
        >
          {user?.avatar_text}
        </Avatar>
      </Dropdown>
    </>
  );
};

export default UserBadge;
