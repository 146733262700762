import "../styles/globals.scss";
import "antd/dist/reset.css";

import type { AppContext, AppProps } from "next/app";

import { ApolloProvider } from "@apollo/client";
import App from "next/app";
import AppLayout from "@shared/containers/app-layout";
import { ConfigProvider } from "antd";
import DocumentTitle from "@shared/components/document-title";
import { NextAdapter } from "next-query-params";
import PrivateRoute from "@shared/guards/private-route";
import PublicRoute from "@shared/guards/public-route";
import { QueryParamProvider } from "use-query-params";
import SeoProvider from "@shared/contexts/seo";
import { SsoProvider } from "@shared/contexts/sso";
import client from "@shared/graphql/client";
import itIT from "antd/lib/locale/it_IT";
import { useIsClient } from "usehooks-ts";
import { useRef } from "react";
import { useRouter } from "next/router";

interface InitialProps {
  appName?: string | undefined;
  oauthClient?: string;
}

interface AllProps extends InitialProps, AppProps {}

function MyApp({ Component, pageProps, appName, oauthClient }: AllProps) {
  // Save these guys in refs so they will stay the same no matter what.
  const appNameRef = useRef(appName);
  const oauthClientRef = useRef(oauthClient);

  const router = useRouter();
  const isClient = useIsClient();

  return (
    <SsoProvider client={oauthClientRef.current}>
      <SeoProvider appName={appNameRef.current}>
        {isClient && (
          <ConfigProvider
            locale={itIT}
            theme={{
              token: {
                fontFamily: "Muli",
                colorPrimary: "#3EB280",
                colorPrimaryBg: "#ebf6f2",
              },
            }}
          >
            <DocumentTitle />

            <ApolloProvider client={client}>
              {router.pathname === "/logout" ? (
                <Component {...pageProps} />
              ) : router.pathname === "/" ? (
                <PublicRoute>
                  <Component {...pageProps} />
                </PublicRoute>
              ) : (
                <PrivateRoute>
                  <AppLayout>
                    <QueryParamProvider adapter={NextAdapter}>
                      <Component {...pageProps} />
                    </QueryParamProvider>
                  </AppLayout>
                </PrivateRoute>
              )}
            </ApolloProvider>
          </ConfigProvider>
        )}
      </SeoProvider>
    </SsoProvider>
  );
}

/**
 * Questa implementazione è un hack perché NextJS chiama ogni richiesta getInitialProps
 * E lato client appContext.ctx.req is undefined
 *
 * Io ho bisogno di questi dati ONCE per sessione, così limito il codice alla sola chiamata ServerSide
 *
 * @see https://github.com/vercel/next.js/discussions/18210#discussioncomment-1202376
 */
MyApp.getInitialProps = async (appContext: AppContext) => {
  const pageProps = await App.getInitialProps(appContext);

  if (process.env.NEXT_PUBLIC_APP_ENV !== "production") {
    return {
      pageProps,
      appName: process.env.NEXT_PUBLIC_APP_NAME,
      oauthClient: process.env.NEXT_PUBLIC_SSO_CLIENT_NAME,
    };
  }

  // Webpack will not include this in client bundle
  if (typeof window === "undefined") {
    // Hacky check from mister @denu5
    // Ensures that the expression under this condition will be executed
    // only once on first page render.
    // If the url starts with /_next/data, that means that we have
    // a client-side navigation situation requiring some additional data
    // from getServerSideProps or getInitialProps (not sure about this one).
    if (!appContext.ctx.req?.url?.startsWith("/_next/data")) {
      const host = appContext.ctx.req?.headers.host;

      // Nome dominio di atterraggio
      // Per local e dev binded to service (aka opiservice)
      const domain = host?.split(".")[0];

      let appName;
      let oauthClient;

      switch (domain) {
        case "partner":
          appName = process.env.NEXT_PUBLIC_APP_NAME_PARTNER;
          oauthClient = process.env.NEXT_PUBLIC_SSO_CLIENT_NAME_PARTNER;
          break;

        case "my":
          appName = process.env.NEXT_PUBLIC_APP_NAME_CLIENTE;
          oauthClient = process.env.NEXT_PUBLIC_SSO_CLIENT_NAME_CLIENTE;
          break;

        default:
          appName = process.env.NEXT_PUBLIC_APP_NAME_SERVICE;
          oauthClient = process.env.NEXT_PUBLIC_SSO_CLIENT_NAME_SERVICE;
          break;
      }

      // Pass data to the page via props
      return { pageProps, appName, oauthClient };
    }
  }

  return {
    pageProps,
  };
};

export default MyApp;
