import React, { FC, PropsWithChildren, ReactNode } from "react";

import { PermissionValue } from "@shared/config/permissions";
import useHasPermission from "@shared/hooks/use-has-permission";

interface UserHasPermissionProps {
  permission: PermissionValue;
  fallback?: ReactNode;
}

const UserHasPermission: FC<PropsWithChildren<UserHasPermissionProps>> = ({
  permission,
  fallback = null,
  children,
}) => {
  const hasPermission = useHasPermission(permission);

  return <>{hasPermission ? children : fallback}</>;
};

export default UserHasPermission;
