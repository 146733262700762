import { FC, PropsWithChildren, createContext, useContext } from "react";

interface ISsoContext {
  client: string | undefined;
  baseUri: string;
}

const SsoContext = createContext<ISsoContext>({
  client: undefined,
  baseUri: process.env.NEXT_PUBLIC_SSO_BASE_URI as string,
});

const useSsoContext = () => {
  const context = useContext(SsoContext);

  if (!context)
    throw new Error("No SsoContext.Provider found when calling useSsoContext.");

  return context;
};

const SsoProvider: FC<PropsWithChildren<Pick<ISsoContext, "client">>> = ({
  client,
  children,
}) => {
  return (
    <SsoContext.Provider
      value={{
        client,
        baseUri: process.env.NEXT_PUBLIC_SSO_BASE_URI as string,
      }}
    >
      {children}
    </SsoContext.Provider>
  );
};

export { SsoProvider, useSsoContext };
