import { FC } from "react";
import Image from "next/image";
import Link from "next/link";
import { dashboardLink } from "@shared/graphql/vars";
import logo from "@public/logo.png";
import logoMarchio from "@public/logo-marchio.png";
import styles from "./Logo.module.scss";
import { useDeviceBreakpoints } from "@shared/hooks/use-device-breakpoints";

const Logo: FC = () => {
  const { lg } = useDeviceBreakpoints();

  return (
    <Link href={dashboardLink()} passHref>
      <a className={styles.Logo} title="Homepage">
        <Image
          src={lg ? logo : logoMarchio}
          alt={process.env.NEXT_PUBLIC_NAME}
        />
      </a>
    </Link>
  );
};

export default Logo;
