import React, { FC, PropsWithChildren } from "react";

import { useAccessToken } from "@shared/hooks/use-access-token";
import { useRouter } from "next/router";

const PrivateRoute: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const router = useRouter();
  const [token] = useAccessToken();

  if (!token) {
    router.push("/");
    return null;
  }

  return <>{children}</>;
};

export default PrivateRoute;
