import { Button, Drawer, Menu } from "antd";
import { FC, ReactNode, useMemo } from "react";

import { MenuOutlined } from "@ant-design/icons";
import NavigationItem from "./navigation-item";
import { Permissions } from "@shared/config/permissions";
import { currentUserVar } from "@shared/graphql/vars";
import styles from "./Navigation.module.scss";
import { useBoolean } from "usehooks-ts";
import { useDeviceBreakpoints } from "@shared/hooks/use-device-breakpoints";
import { useRouter } from "next/router";
import userHasPermission from "@shared/helpers/user-has-permission";

export type MenuItemType = {
  key?: string;
  condition?: boolean;
  label?: ReactNode;
  popupOffset?: number[];
  popupClassName?: string;
  children?: MenuItemType[];
  theme?: "light" | "dark";
  type?: "group" | "divider";
};

const Navigation: FC = () => {
  const router = useRouter();
  const user = currentUserVar()!;
  const { lg } = useDeviceBreakpoints();
  const { value: mobileMenu, toggle } = useBoolean();

  let active = router.pathname;

  const items = useMemo((): any[] => {
    const items: MenuItemType[] = [];

    if (userHasPermission(user, Permissions.SERVIZI)) {
      items.push({
        key: "/servizi",
        label: <NavigationItem href="/servizi" label="Servizi" />,
      });
    }

    if (userHasPermission(user, Permissions.CLIENTI_LIST)) {
      items.push({
        key: "/clienti",
        label: <NavigationItem href="/clienti" label="Clienti" />,
      });
    }

    if (userHasPermission(user, Permissions.SERVIZIO_ATTIVA)) {
      items.push({
        key: "/attiva-servizio",
        label: (
          <NavigationItem href="/attiva-servizio" label="Attiva Servizio" />
        ),
      });
    }

    items.push({
      key: "/attivazioni",
      label: <NavigationItem href="/attivazioni" label="Attivazioni" />,
    });

    // Superpoteri
    const superpowers: MenuItemType[] = [];

    if (userHasPermission(user, Permissions.MANAGEMENT_DOMINI)) {
      superpowers.push({
        theme: "light",
        label: "Domini",
        popupOffset: [-6, 0],
        key: "/domini",
        children: [
          {
            key: "/generazione-named",
            label: (
              <NavigationItem
                label="Generazione named"
                href="/superpoteri/domini/generazione-named"
              />
            ),
          },
        ],
      });
    }

    if (userHasPermission(user, Permissions.MANAGEMENT_DAD)) {
      superpowers.push({
        key: "/eyeonid",
        label: <NavigationItem label="Eyeonid" href="/superpoteri/eyeonid" />,
      });
    }

    if (userHasPermission(user, Permissions.MANAGEMENT_OFFICE365)) {
      superpowers.push({
        theme: "light",
        label: "Office",
        popupOffset: [-6, 0],
        key: "/office",
        children: [
          {
            key: "/elimina-caselle",
            label: (
              <NavigationItem
                label="Elimina caselle"
                href="/superpoteri/office/elimina-caselle"
              />
            ),
          },
        ],
      });
    }

    if (userHasPermission(user, Permissions.MANAGEMENT_PRIVATE_CLOUD)) {
      superpowers.push({
        key: "/private-cloud",
        label: (
          <NavigationItem
            label="Private cloud"
            href="/superpoteri/private-cloud"
          />
        ),
      });
    }

    if (userHasPermission(user, Permissions.ZIMBRA_DOMAIN_CREATE)) {
      superpowers.push({
        theme: "light",
        label: "Zimbra",
        popupOffset: [-6, 0],
        key: "/zimbra",
        children: [
          {
            key: "/nuovo-dominio",
            label: (
              <NavigationItem
                label="Nuovo dominio"
                href="/superpoteri/zimbra/nuovo-dominio"
              />
            ),
          },
        ],
      });
    }

    if (user.is_opiquad) {
      superpowers.push({
        key: "/superpoteri/statistiche-commerciali",
        label: (
          <NavigationItem
            label="Statistiche commerciali"
            href="/superpoteri/statistiche-commerciali"
          />
        ),
      });
    }

    if (superpowers.length) {
      items.push({
        theme: "light",
        key: "/superpoteri",
        label: "Superpoteri",
        popupOffset: [-10, -10],
        children: superpowers,
      });
    }

    return items;
  }, [user]);

  // Handle sub routes senza sub menù
  if (
    router.pathname.startsWith("/clienti/") ||
    router.pathname.startsWith("/servizio/")
  ) {
    active = "/clienti";
  } else if (router.pathname.startsWith("/attiva-servizio")) {
    active = "/attiva-servizio";
  }

  if (!lg) {
    return (
      <>
        <Button
          type="link"
          onClick={toggle}
          icon={<MenuOutlined />}
          className={styles.MobileTrigger}
        />

        <Drawer
          placement="left"
          onClose={toggle}
          open={mobileMenu}
          className={styles.Drawer}
        >
          <Menu
            items={items}
            mode="inline"
            onSelect={toggle}
            selectedKeys={[active]}
            className={styles.MobileMenu}
          />
        </Drawer>
      </>
    );
  }

  return (
    <Menu
      theme="dark"
      items={items}
      mode="horizontal"
      selectedKeys={[active]}
      className={styles.Navigation}
    />
  );
};

export default Navigation;
