import { gql } from "@apollo/client";

export const typeDefs = gql`
  extend type Row {
    isEditable: Boolean!
  }

  extend type User {
    isAdmin: Boolean!
  }
`;
