import { Avatar, Space, Tag, Typography } from "antd";
import React, { FC, ReactNode } from "react";

import Link from "next/link";
import { UrlObject } from "url";
import styles from "./NavigationItem.module.scss";

interface NavigationItemProps {
  href: string | UrlObject;
  novita?: boolean;
  label: ReactNode;
  icon?: ReactNode;
  subtitle?: string;
}

const NavigationItem: FC<NavigationItemProps> = ({
  href,
  label,
  novita = false,
  icon = null,
  subtitle = null,
}) => {
  const title = (
    <>
      {label}
      {novita && (
        <Tag color="orange" className={styles.Novita}>
          Novità
        </Tag>
      )}
    </>
  );

  return (
    <Link passHref href={href}>
      <a className={styles.Link}>
        {icon && <Avatar icon={icon} className={styles.Icon} />}
        {!subtitle ? (
          title
        ) : (
          <Space direction="vertical" size={2}>
            <span>{title}</span>

            <Typography.Text type="secondary" className={styles.Subtitle}>
              {subtitle}
            </Typography.Text>
          </Space>
        )}
      </a>
    </Link>
  );
};

export default NavigationItem;
