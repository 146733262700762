import { useMediaQuery } from "usehooks-ts";

interface DeviceBreakpoints {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  xxl: boolean;
}

export const useDeviceBreakpoints = (): DeviceBreakpoints => {
  const xs = useMediaQuery("(max-width: 575px)");
  const sm = useMediaQuery("(min-width: 576px)");
  const md = useMediaQuery("(min-width: 768px)");
  const lg = useMediaQuery("(min-width: 992px)");
  const xl = useMediaQuery("(min-width: 1200px)");
  const xxl = useMediaQuery("(min-width: 1600px)");

  return {
    xs,
    sm,
    md,
    lg,
    xl,
    xxl,
  };
};
