import { Layout, Space } from "antd";
import React, { FC } from "react";
import { SearchOutlined, UsergroupAddOutlined } from "@ant-design/icons";

import IconLink from "./icon-link";
import Logo from "@shared/components/logo";
import Navigation from "@shared/components/navigation";
import { Permissions } from "@shared/config/permissions";
import RepresentedCustomerNotice from "@shared/components/represented-customer-notice";
import UserBadge from "@shared/components/user-badge";
import UserHasPermission from "@shared/components/user-has-permission";
import { currentUserVar } from "@shared/graphql/vars";
import styles from "./Header.module.scss";
import { useDeviceBreakpoints } from "@shared/hooks/use-device-breakpoints";
import { useReactiveVar } from "@apollo/client";
import { useRouter } from "next/router";

const Header: FC = () => {
  const router = useRouter();
  const { md } = useDeviceBreakpoints();
  const user = useReactiveVar(currentUserVar);

  return (
    <>
      <div className={styles.Header}>
        {user?.is_cliente && (
          <RepresentedCustomerNotice
            customer={user.customer!.ragione_sociale}
            canChange={
              user.represented_customers &&
              user.represented_customers?.length > 1
                ? true
                : false
            }
          />
        )}

        <Layout.Header>
          <div className={styles.Main}>
            <Space align="center" size={md ? 32 : 16} className={styles.Space}>
              <Logo />
              <Navigation />
            </Space>

            <Space align="center" size={8}>
              <UserHasPermission permission={Permissions.USERS_READ}>
                <IconLink
                  href="/utenti"
                  icon={<UsergroupAddOutlined />}
                  active={router.pathname.startsWith("/utenti")}
                />
              </UserHasPermission>

              <UserHasPermission permission={Permissions.RICERCA}>
                <IconLink
                  href="/ricerca"
                  icon={<SearchOutlined />}
                  active={router.pathname === "/ricerca"}
                />
              </UserHasPermission>

              <UserBadge />
            </Space>
          </div>
        </Layout.Header>
      </div>
    </>
  );
};

export default Header;
