import { FC, PropsWithChildren, createContext } from "react";

interface SeoProviderProps {
  appName: string | undefined;
}

export const SeoContext = createContext<string | undefined>(undefined);

const SeoProvider: FC<PropsWithChildren<SeoProviderProps>> = ({
  appName,
  children,
}) => {
  return <SeoContext.Provider value={appName}>{children}</SeoContext.Provider>;
};

export default SeoProvider;
