import { Login_login } from "@shared/graphql/types/Login";
import { PermissionValue } from "@shared/config/permissions";
import { currentUserVar } from "@shared/graphql/vars";
import { useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import userHasPermission from "@shared/helpers/user-has-permission";

const useHasPermission = (permission: PermissionValue): boolean => {
  const user = useReactiveVar<Login_login | null>(currentUserVar);

  const hasPermission = useMemo(
    () => (user ? userHasPermission(user, permission) : false),
    [user, permission]
  );

  return hasPermission;
};

export default useHasPermission;
